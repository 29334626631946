<script setup>
import { onMounted, ref } from 'vue';

defineProps({
    hasError: {
        type: Boolean,
    },
});

const model = defineModel({
    type: [String, Number],
});

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        ref="input"
        v-model="model"
        class="w-full bg-white input peer"
        :class="{ 'input-error': hasError }"
    />
</template>
